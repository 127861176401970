var url = window.location.href;
var progressBar, pathFlow;

// Wanna cookie?
var getCookie = function(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
        return parts
            .pop()
            .split(';')
            .shift();
    }
};

var flowControl = function(type_of_debt) {
    switch (type_of_debt) {
        case 'Student Loans':
            progressBar = {
                0: '0%',
                1: '17%',
                2: '34%',
                3: '51%',
                4: '68%',
                5: '85%',
                6: '95%'
            };
            pathFlow = {
                0: '/type_of_debt',
                1: '/student_debt',
                2: '/amount_of_debt_sd',
                3: '/enrolled_in_school',
                4: '/income_sd',
                5: '/affordable_payments',
                6: '/contact_info'
            };
            break;
        case 'Tax':
            progressBar = {
                0: '0%',
                1: '50%',
                2: '90%'
            };
            pathFlow = {
                0: '/type_of_debt',
                1: '/amount_of_debt',
                2: '/contact_info'
            };
            break;
        default:
            if (typeof pathFlow === 'undefined') {
                pathFlow = {
                    0: '/type_of_debt',
                    1: '/income',
                    2: '/amount_of_debt',
                    3: '/payment_status',
                    4: '/program_status',
                    5: '/contact_info'
                };
            }
            progressBar = {
                0: '0%',
                1: '20%',
                2: '40%',
                3: '60%',
                4: '80%',
                5: '90%'
            };
    }
};

// Moves user forward in path
var nextPage = function() {
    var pageUrl = '';
    flowControl(getCookie('type_of_debt'));
    for (var key in pathFlow) {
        if (url.indexOf(pathFlow[key]) >= 0) {
            pageUrl = pathFlow[parseInt(key) + 1];
        }
    }
    return pageUrl || pathFlow[1];
};

// Returns user to previous page in flow
var backPage = function() {
    flowControl(getCookie('type_of_debt'));
    for (var key in pathFlow) {
        if (url.indexOf(pathFlow[key]) >= 0 && key > 0) {
            return pathFlow[parseInt(key) - 1];
        }
    }
};

var regexConv = function(elem) {
    return elem.match(/\d+/g)[0];
};

var successResponseCallback = function() {
    window.location = '/survey_thankyou';
};

var invalidResponseCallback = function() {
    window.location = '/survey_pending';
};

var generatePostData = function() {
    var dataToPost = {};
    $('#main-form :input').each(function(i, el) {
        el = $(el);
        if (el.attr('type') === 'radio' || el.attr('type') === 'checkbox') {
            if (!el.is(':checked')) {
                return;
            }
        }
        dataToPost[el.attr('name')] = el.val();
    });
    return dataToPost;
};

var processForm = function() {
    var postData = generatePostData();
    $('#leadresponse_container').load('/process', postData, function() {
        var leadResponse = $('input#avt_lead_response').val();
        if (leadResponse === 'success') {
            successResponseCallback();
        } else {
            invalidResponseCallback();
        }
    });
};

// Checks the user's input against accepted values set in HTML data attributes
var checkAnswer = function() {
    var test = true;
    $('.radio.selected, .radio-container.selected').each(function(i, el) {
        if (
            typeof $(el)
                .parent()
                .attr('data-accepted-string') !== 'undefined'
        ) {
            if (
                $(el)
                    .parent()
                    .attr('data-accepted-string') !== $(el).text()
            ) {
                test = false;
            }
        }

        if (
            typeof $(el)
                .parent()
                .attr('data-accepted-multi') !== 'undefined'
        ) {
            var items = $(el)
                .parent()
                .attr('data-accepted-multi')
                .split('|');
            if (items.indexOf($(el).text()) < 0) {
                test = false;
            }
        }
    });
    return test;
};

// Stores the users answer into a cookie with the ID of the parent element
var storeAnswer = function() {
    var now = new Date();
    now.setDate(now.getDate() + 1);
    document.cookie =
        $('.selected')
            .parent()
            .prop('id') +
        '=' +
        $('.selected').text() +
        '; expires=' +
        now.toGMTString();
};

// Amount of Debt page (Slider only appears on desktop)
var debtConv = function(number) {
    var value = $('.debt-radio').last().attr('value');
    $('.debt-radio').each(function(i, el) {
        el = $(el);
        if (number >= parseInt(el.attr('min')) && number <= parseInt(el.attr('max'))) {
            value = el.attr('value');
        }
    });
    return value;
};

$(document).ready(function() {

    // This will define pathFlow based off the cookie storage for debt type
    flowControl(getCookie('type_of_debt'));

    // Sets the progress bar percentage
    // Different paths have different numbers
    for (var key in pathFlow) {
        if (pathFlow.hasOwnProperty(key)) {
            if (url.indexOf(pathFlow[key]) >= 0) {
                $('.progress-bar').css('width', progressBar[key]);
            }
        }
    }

    $('#previous-page').click(function(e) {
        e.preventDefault();
        window.location = backPage();
    });

    $('#next-page').click(function(e) {
        e.preventDefault();
        window.location = nextPage();
    });

    // Selects answer if user has previously selected it
    // Also displays forward button if they've already visited that page in the path
    $('.container-survey > div').each(function(i, el) {
        el = $(el);
        if (getCookie(el.attr('id')) && $('#survey-slider').length === 0) {
            var compare = getCookie(el.attr('id'));
            $(el.children()).each(function(ci, cel) {
                cel = $(cel);
                if (cel.text() === compare) {
                    cel.addClass('selected');
                }

                if (cel.text() === compare && checkAnswer() === true) {
                    $('.forward-button').css('visibility', 'visible');
                }
            });
        }

        if (
            getCookie('selected_debt_amount') &&
            $('#survey-slider').length > 0
        ) {
            $('#survey-slider').val(getCookie('selected_debt_amount'));
            $('.forward-button').css('visibility', 'visible');
        }
    });

    // Checks input validation if a user leaves the field
    $('input').blur(function() {
        $(this)
            .parsley()
            .validate();
    });

    $('.radio, .payment-radio').click(function() {
        $('.radio, .payment-radio').removeClass('selected');
        $(this).addClass('selected');
    });

    // Appends the selected debt amount to a div
    $('#debt-amount').text('$' + $('#survey-slider').val());
    $('#survey-slider').on('input', function() {
        if ($('#survey-slider').val() === $('#survey-slider').attr('max')) {
            $('#debt-amount').text('$' + $('#survey-slider').val() + '+');
        } else {
            $('#debt-amount').text('$' + $('#survey-slider').val());
        }
    });

    for (key in pathFlow) {
        if (
            url.indexOf(pathFlow[key]) >= 0 &&
            url.indexOf('/amount_of_debt') < 0
        ) {
            $('label.radio, label.payment-radio, .radio').click(function() {
                storeAnswer();
                if (checkAnswer() === true) {
                    window.location = nextPage();
                } else {
                    window.location = '/survey_pending';
                }
            });
        }
    }

    // Type of Debt page
    $('#debt_type_btn').click(function() {
        storeAnswer();
        if ($('.radio.selected').length > 0) {
            if (checkAnswer() === true) {
                $('#dtype_error').remove();
                window.location = nextPage();
            } else {
                window.location = '/survey_pending';
            }
        } else {
            $('#dtype_error').css('display', 'block');
        }
    });

    $('#slider_btn, .debt-radio').click(function(e) {
        e.preventDefault();
        var value;
        if ($('.additional-info-mobile').is(':visible')) {
            if ($('.debt-radio.selected').length > 0) {
                value = parseInt(regexConv($('.debt-radio.selected').text()));
                document.cookie =
                    'amount_of_debt=' + $('.debt-radio.selected').text();
            }
        } else if ($('.additional-info-nonmobile').is(':visible')) {
            value = parseInt($('#survey-slider').val());
            document.cookie = 'selected_debt_amount=' + value;
            document.cookie = 'amount_of_debt=' + debtConv(value);
        }

        if (
            value < parseInt($('#debt_amount').attr('data-accepted-number'), 10)
        ) {
            if (getCookie('type_of_debt') === 'Student Loans') {
                window.location = '/contact_info';
            } else {
                window.location = '/survey_pending';
            }
        } else {
            window.location = nextPage();
        }
    });

    // Contact Info page
    $('#contact_btn').click(function(e) {
        e.preventDefault();
        $('#main-form')
            .parsley()
            .validate('contact_info');
        if (
            $('#main-form')
                .parsley()
                .isValid({ group: 'contact_info' })
        ) {
            $('.modal').css('display', 'block');
            processForm();
        }
    });
});
